import React from 'react'
import ReactLoaderSpinner from 'react-loader-spinner'
import { Button, Container, Form } from 'react-bootstrap'

// Styles
import './Homepage.scss'

// Components
import Logo from '../../assets/logo.png'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import { MdCheck, MdMailOutline } from 'react-icons/md'

class Homepage extends React.Component {
  state = {
    email: '',
    loading: false,
    submitted: false,
  }

  handleSubmit = event => {
    this.setState({
      loading: true,
      submitted: false,
    })

    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: this.encode({
        'form-name': 'mailing-list',
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({
          loading: false,
          submitted: true,
        })
      })
      .catch(error => {
        alert(error)

        this.setState({
          loading: false,
          submitted: false,
        })
      })

    event.preventDefault()
  }

  handleChange = event => {
    const { value, name } = event.target

    this.setState({
      [name]: value,
    })
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  getYear = () => {
    return new Date().getFullYear()
  }

  render () {
    const { email, loading, submitted } = this.state

    return (
      <Container className="paylog-homepage">
        <div className="header">
          <div className="logo-container">
            <img src={Logo}

                 className="logo" alt="logo"/>
            <p className="app-name">PayLog</p>
          </div>
          <div className="email-container">
            <MdMailOutline className="email-icon"/>
            <a href="mailto:info@paylog.io">info@paylog.io</a>
          </div>
        </div>
        <div className="content-container">
          <div className="content">
            <h1>We're coming soon</h1>

            <div className="socials-container">
              <p>Follow our socials.</p>
              <div className="socials">
                <a className="social facebook" href="https://facebook.com/paylog.io" target="_blank" rel="noreferrer">
                  <FaFacebook className="social-icon"/>
                </a>

                <a className="social twitter" href="https://twitter.com/paylog_io" target="_blank" rel="noreferrer">
                  <FaTwitter className="social-icon"/>
                </a>

                <a className="social instagram" href="https://instagram.com/paylog.io" target="_blank" rel="noreferrer">
                  <FaInstagram className="social-icon"/>
                </a>

                <a className="social email" href="mailto:info@paylog.io" target="_blank" rel="noreferrer">
                  <MdMailOutline className="social-icon"/>
                </a>
              </div>
            </div>

            <div className="mailing-container">
              <p>Stay connected for future updates.</p>

              <Form onSubmit={this.handleSubmit} name="mailing-list" className="mailing-form">
                <Form.Control
                  className="mailing-email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  placeholder="my@email.com"
                  disabled={loading || submitted}
                />
                <Button type="submit" className="mailing-submit" variant="secondary" disabled={loading || submitted}>
                  {
                    loading
                      ? <ReactLoaderSpinner
                        className="loading"
                        type="TailSpin"
                        color="#FFFFFF"
                        width={20}
                        height={20}
                      />
                      :
                      submitted
                        ? <MdCheck/>
                        : 'Submit'
                  }
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <div className="footer">
          <p className="copyright">&copy; {this.getYear()} <b>PayLog</b> | All rights reserved.</p>
        </div>
      </Container>
    )
  }
}

export default Homepage
