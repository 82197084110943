import React from 'react'
import { Route } from 'react-router-dom'

// Constants
import { URL_HOME } from '../constants/url'

// Views
import Homepage from '../views/Homepage/Homepage'

const Routes = [
  <Route exact key={URL_HOME} path={URL_HOME} component={Homepage}/>,
]

export default Routes
