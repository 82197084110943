import React from 'react'
import { Switch } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

// Routes
import Routes from './routes/Routes'

class App extends React.Component {
  render () {
    return (
      <main className="main bg-dark text-white">
        <Container className="main-container" fluid>
          <Row className="main-container-row">
            <Col className="main-container-col">
              <Switch>
                {Routes}
              </Switch>
            </Col>
          </Row>
        </Container>
      </main>
    )
  }
}

export default App
